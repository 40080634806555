import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

// Services
import RoleService from "../../services/RoleService";
import UserService from "../../services/UserService";

// Interfaces
import Role from "../../Interfaces/Entities/Role";
import AddUserDialogInterface from "../../Interfaces/Users/AddUserDialogInterface";

const roleService = new RoleService();
const userService = new UserService();
const AddNewUserDialog: React.FC<AddUserDialogInterface> = ({
  onCancel,
  onSubmit,
}) => {
  const [availableRoles, setAvailableRoles] = useState<Role[]>([]);
  const [chosenRole, setChosenRole] = useState<string>("");
  const [error, setError] = useState<string>("");
  const { handleSubmit, errors, register } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    roleService.getRoles().then((rolesResult: Role[] | string) => {
      if (rolesResult) {
        if (typeof rolesResult === "object") {
          setAvailableRoles(rolesResult);
        } else {
          setError(rolesResult);
        }
      }
    });
  }, []);

  const roleOptions = () => {
    return availableRoles.map((role: Role) => {
      return (
        <option value={role.id} key={role.id}>
          {role.name}
        </option>
      );
    });
  };

  const onSubmitNewUser = (data: any) => {
    userService.inviteUser(data).then(inviteResult => {
      if (typeof inviteResult === "object") {
        onSubmit(data.email);
      } else {
        setError(inviteResult);
      }
    })
  };

  return (
    <div className="c-modal">
      <h2>Add new user</h2>
      <p>
      Please fill in the email address and role for the new user. After submitting, an email will be sent to this email address with a request to create an account.
      </p>

      <form onSubmit={handleSubmit(onSubmitNewUser)}>
        <div className="c-modal__column">
          <input
            ref={register({ required: true })}
            type="email"
            placeholder="Email address"
            name="email"
            className={errors.email ? "c-input is-error" : "c-input"}
          />
          {errors.email && (
            <span className="c-users__error">Email address is required.</span>
          )}

          <select
            defaultValue={chosenRole}
            onChange={(event) => setChosenRole(event.target.value)}
            className="c-select"
            name="role"
            ref={register({ required: true })}
          >
            <option disabled value="">
              Role
            </option>
            {roleOptions()}
          </select>
          {errors.role && (
            <span className="c-users__error">Role is required.</span>
          )}
        </div>
        {error && <span className="c-users__error">{error}</span>}
        <div className="c-modal__row--reverse">
          <button
            className="c-button c-button--solid"
            onSubmit={handleSubmit(onSubmitNewUser)}
          >
            Submit
          </button>
          <button type="button" className="c-button c-button" onClick={() => onCancel()}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNewUserDialog;
