import axios from "../api/baseApi";
import {
  CountrySportCombination,
  CountrySportCombinationDetails,
} from "../Interfaces/CountrySportCombinations/CountrySportCombination";
import { PageResult } from "../Interfaces/generic/page-result";

// Constants
import BASE_API_URLS from "../constants/ApiBaseUrls";

// Interfaces
import NewCountrySportCombinationInterface from "../Interfaces/CountrySportCombinations/NewCountrySportCombinationInterface";

export default class CountrySportCombinationService {
  async loadCountrySportCombinations(
    page: number = 1,
    pageSize?: number
  ): Promise<PageResult<CountrySportCombination>> {
    try {
      if (!pageSize) {
        pageSize = +process.env.REACT_APP_TABLE_DEFAULT_PAGESIZE!;
      }

      const response = await axios.get(
        `${BASE_API_URLS.BE}union?page=${page}&pageSize=${pageSize}`
      );

      if (response.status === 200) {
        return {
          results: response.data,
          totalCount: response.headers["x-total-count"],
        };
      } else {
        return { totalCount: 0 };
      }
    } catch (error) {
      return { totalCount: 0 };
    }
  }

  async loadPrefilledUnion(countryCode: string, sport: string) {
    try {
      const result = await axios.get(
        `${BASE_API_URLS.BE}fifpro-union/${countryCode}/${sport}`
      );

      if (result.status === 200) {
        return result.data;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async loadCountrySportCombinationDetails(country: string, sport: string) {
    try {
      const result = await axios.get(
        `${BASE_API_URLS.BE}union/${country}/${sport}`
      );

      if (result.status === 200) {
        return result.data;
      } else {
        console.log("Something went wrong");
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async saveCountrySportCombination(
    country: string,
    sport: string,
    details: CountrySportCombinationDetails
  ) {
    const payload = {
      ...details,
      logoBackgroundColor: details.logoBackgroundColor ? "#FFF" : null,
      address: "NVT",
    };

    const result = await axios.put(
      `${BASE_API_URLS.BE}union/${country}/${sport}`,
      payload
    );

    if (result && result.status === 204) {
      return { success: result };
    } else {
      return { error: result };
    }
  }

  async checkIfCombinationExists(
    combination: NewCountrySportCombinationInterface
  ) {
    try {
      if (!combination.country || !combination.sport) {
        return false;
      } else {
        const result = await axios.get(
          `${BASE_API_URLS.BE}union/validate/${combination.country}/${combination.sport}`
        );
        if (result && result.status === 200) {
          return result.data;
        }
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async isUserAssignedToOtherUnion(
    unionCode: string,
    email: string
  ): Promise<boolean | string> {
    try {
      if (!unionCode || !email) {
        return "Incorrect data was supplied to check if the user can be added.";
      }

      const result = await axios.get(
        `${BASE_API_URLS.BE}union/isassignedtootherunion/${unionCode}/${email}`
      );

      switch (result.status) {
        case 200:
          return result.data;
        default:
          return false;
      }
    } catch (error) {
      switch (error.response.status) {
        case 400:
          return "This user cannot be added as a casehandler.";
        case 403:
          return "This user cannot be added as a casehandler.";
        default:
          return "Something went wrong, please try again later.";
      }
    }
  }
}
