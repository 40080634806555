const ROUTES = {
  ROOT: "/",
  LOGIN: "/login",
  LOGOUT: "/logout",
  INDEX: "/index",
  HOME: "/home",
  REPORT: "/report",
  REPORT_DETAILS: "/report/:id",
  SPORT_COMBINATION_OVERVIEW: "/sport-combinations",
  SPORT_COMBINATION_DETAILS: "/sport-combinations/details/:country?/:sport?",
  SPORT_COMBINATION_EDIT: "/sport-combinations/edit/:country?/:sport?/:isNew?",
  PLAYER_REPORT_CODES: "/player-report-codes",
  USER_OVERVIEW: "/user-overview",
};

export default ROUTES;
