import React, { useState, useMemo, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import { ColumnWithLooseAccessor, usePagination, useTable } from "react-table";
import { UserData } from "react-oidc";
import Modal from "react-modal";

// Interfaces
import { CountrySportCombinationRow } from "../Interfaces/CountrySportCombinations/CountrySportCombination";

// Services
import CountrySportCombinationService from "../services/CountrySportCombinationService";
import { isAdmin } from "../services/RoleService";

// Components
import PageWrapper from "../components/molecules/PageWrapper";
import PaginationButtons from "../components/molecules/PaginationButtons";
import NewCountrySportCombinationDialog from "../components/molecules/NewSportCountryCombinationDialog";
import DataTable from "../components/molecules/DataTable";

// Consts
import TABLEHEADERS from "../constants/TableHeaders";
import ROUTES from "../constants/routes";

const countrySportCombinationService = new CountrySportCombinationService();
Modal.setAppElement("#root");

const CountrySportCombinationsOverview: React.FC = () => {
  const { user } = useContext(UserData);
  const [isNewCSCModalOpen, setIsNewCSCModalOpen] = useState(false);
  const [rows, setRows] = useState<CountrySportCombinationRow[]>([]);
  const [pages, setPages] = useState(0);
  const history = useHistory();
  const columns: ColumnWithLooseAccessor[] = useMemo(() => {
    return [
      {
        Header: "Country",
        accessor: TABLEHEADERS.COUNTRY,
      },
      {
        Header: "Sport",
        accessor: TABLEHEADERS.SPORT,
      },
    ];
  }, []);

  const {
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: rows,
      pageCount: pages,
      initialState: {
        pageIndex: 0,
        pageSize: +process.env.REACT_APP_TABLE_DEFAULT_PAGESIZE!,
      },
      manualPagination: true,
    },
    usePagination
  );

  useEffect(() => {
    if (user && !isAdmin(user.profile.role)) {
      history.push(ROUTES.ROOT);
    } else {
      countrySportCombinationService
        .loadCountrySportCombinations(pageIndex + 1, pageSize)
        .then((res) => {
          if (res && res.results) {
            const mappedResults = res.results.map((csc) => {
              return {
                country: csc.country.countryName,
                countryCode: csc.country.countryCode,
                sport: csc.sport.name,
              };
            });
            setRows(mappedResults ?? []);
          }

          setPages(Math.ceil(res.totalCount / pageSize));
        });
    }
  }, [pageIndex, pageSize, history, user]);

  const rowClicked = (row: any) => {
    history.push(
      `/sport-combinations/details/${row.original.countryCode}/${row.original.sport}`
    );
  };

  return (
    <main>
      <PageWrapper title="Country/Sport overview" panelSize="l-content__large">
        <div className="">
          <div className="l-container">
            <div className="c-panel">
              <div className="l-scroll-container l-scroll-container--table">
                <DataTable columns={columns} data={rows} onRowClick={rowClicked} />
              </div>

              <div className="c-table-footer">
                <div className="c-pagination">
                  {PaginationButtons(pages, pageIndex, gotoPage)}
                </div>
                <button
                  className="c-button c-button--solid"
                  onClick={() => setIsNewCSCModalOpen(true)}
                >
                  add new country/sport
                  <span className="icon-plus"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
      <Modal
        isOpen={isNewCSCModalOpen}
        onRequestClose={() => setIsNewCSCModalOpen(false)}
        shouldCloseOnEsc={true}
        shouldFocusAfterRender={true}
        shouldCloseOnOverlayClick={true}
      >
        <NewCountrySportCombinationDialog
          dismissModal={() => setIsNewCSCModalOpen(false)}
        />
      </Modal>
    </main>
  );
};

export default CountrySportCombinationsOverview;
