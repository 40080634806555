import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { UserData } from "react-oidc";

// Services
import { isAdmin } from "../services/RoleService";
import CountrySportCombinationService from "../services/CountrySportCombinationService";

// Components
import PageWrapper from "../components/molecules/PageWrapper";

// Constants
import ROUTES from "../constants/routes";
import { CountrySportCombinationDetails as CountrySportCombinationDetailsInterface } from "../Interfaces/CountrySportCombinations/CountrySportCombination";

const countrySportCombinationService = new CountrySportCombinationService();

const CountrySportCombinationDetails: React.FC = () => {
  const [details, setDetails] = useState<
    CountrySportCombinationDetailsInterface
  >();
  const { user } = useContext(UserData);
  const { country, sport } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (user) {
      if (!isAdmin(user.profile.role)) {
        history.push(ROUTES.REPORT);
      }

      countrySportCombinationService
        .loadCountrySportCombinationDetails(country, sport)
        .then((result) => {
          if (result && result) {
            setDetails(result ?? {});
          }
        });
    }
  }, [country, sport, user, history]);

  const handlerItems = () => {
    if (details && details.handlers) {
      return details!.handlers!.map((handler) => {
        return (
          <div className="c-csc__field" key={handler.id}>
            <strong>
              Handler
            </strong>
            <a href={`mailto:${handler.email}`}>{handler.email}</a>
          </div>
        );
      });
    } else {
      return [];
    }
  };

  if (!details) {
    return (
      <main>
        <PageWrapper
          title={`${country}/${sport}`}
          backButtonRequired={true}
          panelSize="l-content__large"
        >
          <div className="c-csc__check-logo c-loader"></div>
        </PageWrapper>
      </main>
    );
  }

  return (
    <main>
      <PageWrapper
        title={`${country}/${sport}`}
        backButtonRequired={true}
        panelSize="l-content__large"
      >
        <div className="c-csc__container">
          <div
            className="c-csc__logo"
            style={{ background: details?.logoBackgroundColor || "#221c46" }}
          >
            <img src={details?.logoPath} alt={details?.name} />
          </div>
          <div className="c-csc__details-block">
            <h5 className="c-csc__category-title">Union</h5>
            <div className="c-csc__field">
              <strong>Code</strong>
              <span>{details?.unionCode}</span>
            </div>
            <div className="c-csc__field">
              <strong>Name</strong>
              <span>{details?.name}</span>
            </div>
            <div className="c-csc__field">
              <strong>Short name</strong>
              <span>{details?.shortName}</span>
            </div>
            <div className="c-csc__field">
              <strong>Background</strong>
              <label className="c-checkbox">
                  <input
                    type="checkbox"
                    defaultChecked={!!details?.logoBackgroundColor}
                    disabled={true}
                    defaultValue="LogoBackgroundColor"
                    className="c-checkbox__input"
                  />
                  <span className="c-checkbox__label">White background color</span>
              </label>
            </div>
            <div className="c-csc__details-block">
              <h5 className="c-csc__category-title">Union address</h5>
              <div className="c-csc__field">
                <strong>Address line 1</strong>
                <span>{details?.addressLine1}</span>
              </div>
              <div className="c-csc__field">
                <strong>Address line 2</strong>
                <span>{details?.addressLine2}</span>
              </div>
              <div className="c-csc__field">
                <strong>Postal code</strong>
                <span>{details?.postalCode}</span>
              </div>
              <div className="c-csc__field">
                <strong>City</strong>
                <span>{details?.city}</span>
              </div>
              <div className="c-csc__field">
                <strong>Province</strong>
                <span>{details?.province}</span>
              </div>
            </div>
            <div className="c-csc__details-block">
              <h5 className="c-csc__category-title">Union Contact Info</h5>
              <div className="c-csc__field">
                <strong>Contact person name</strong>
                <span>{details?.contactPersonName}</span>
              </div>
              <div className="c-csc__field">
                <strong>Contact email address</strong>
                <span>{details?.contactEmailAddress}</span>
              </div>
              <div className="c-csc__field">
                <strong>Contact phone number</strong>
                <span>{details?.contactPhoneNumber}</span>
              </div>
            </div>
            <div className="c-csc__details-block">
              <h5 className="c-csc__category-title">Extra options</h5>
              <div className="c-csc__field">
                <strong>Default language</strong>
                <span>{details?.defaultLanguage}</span>
              </div>
              <div className="c-csc__field">
                <strong>Translation group</strong>
                <span>{details?.translationGroup}</span>
              </div>
              <div className="c-csc__details-block">
                <h5 className="c-csc__category-title">Handler</h5>
                <div className="c-csc__field">
                  <strong>FIFPRO as handler</strong>
                  <label className="c-checkbox">
                    <input
                      type="checkbox"
                      defaultChecked={details?.handledByFifpro}
                      disabled={true}
                      defaultValue="HandledBy"
                      className="c-checkbox__input"
                    />
                    <span className="c-checkbox__label">Use FIFPRO as handler</span>
                  </label>
                </div>
                {handlerItems()}
              </div>
            </div>
            <div className="c-csc__actions">
              <button
                onClick={() =>
                  history.push(`/sport-combinations/edit/${country}/${sport}`)
                }
                className="c-button c-button--solid"
                type="button"
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      </PageWrapper>
    </main>
  );
};

export default CountrySportCombinationDetails;
