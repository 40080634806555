import axios from "../api/baseApi";

// Constants
import BASE_API_URLS from "../constants/ApiBaseUrls";

// Interfaces
import Handler from "../Interfaces/Entities/Handler";
import User from "../Interfaces/Entities/User";
import UserInvitationInterface from "../Interfaces/Users/UserInvitationInterface";
import GetUsersInterface from "../Interfaces/Users/GetUsersInterface";
import { PageResult } from "../Interfaces/generic/page-result";

export default class UserService {
  async getUserByEmail(email: string): Promise<Handler | string> {
    try {
      const user = await axios.get(
        `${BASE_API_URLS.IDS}user?email=${encodeURIComponent(email)}&page=1&pageSize=1`
      );

      switch (user.status) {
        case 200:
          return user.data;
        case 401:
          return "You have insufficient permissions to view this data.";
        case 400:
          return "Something went wrong, please try again later.";
        case 403:
          return "Your session has expired, please login again.";
        case 404:
          return "User with this email address was not found.";
        default:
          return "Something went wrong, please try again later.";
      }
    } catch (error) {
      return "Something went wrong, please try again later.";
    }
  }

  async getUsers(
    paging: GetUsersInterface
  ): Promise<PageResult<User> | string> {
    try {
      const usersResult = await axios.get(
        `${BASE_API_URLS.IDS}user?page=${paging.page}&pageSize=${paging.pageSize}`
      );

      switch (usersResult.status) {
        case 200:
          return {
            data: usersResult.data,
            totalCount: usersResult.headers["x-total-count"],
          } as PageResult<User>;
        default:
          return "Something went wrong while trying to retrieve the users, please try again later.";
      }
    } catch (error) {
      switch (error.response.status) {
        case 401:
          return "Your session has expired, please login again.";
        case 403:
          return "You have insufficient permissions to view this information.";
        case 404:
          return "Users were not found, please try again later.";
        default:
          return "Something went wrong while trying to retrieve the users, please try again later.";
      }
    }
  }

  async inviteUser(
    invitation: UserInvitationInterface
  ): Promise<User | string> {
    if (!invitation.email || !invitation.role) {
      return "Email address or role was not supplied";
    }

    try {
      const inviteResult = await axios.post(`${BASE_API_URLS.IDS}user`, {
        email: invitation.email,
        roleId: invitation.role,
      });

      switch (inviteResult.status) {
        case 200:
          return inviteResult.data;
        default:
          return "Something went wrong while trying to invite the user, please try again later.";
      }
    } catch (error) {
      switch (error.response.status) {
        case 401:
          return "Your session has expired, please login again.";
        case 403:
          return "You have insufficient permissions to view this information.";
        case 404:
          return "Invitation could not be sent for this email address.";
        case 409:
          return "This email address is already in use for an account.";
        default:
          return "Something went wrong while trying to invite the user, please try again later.";
      }
    }
  }

  async reInviteUser(userId: string): Promise<boolean | string> {
    if (!userId) {
      return "Email address or role was not supplied";
    }

    try {
      const inviteResult = await axios.post(`${BASE_API_URLS.IDS}user/${userId}/invite`);

      switch (inviteResult.status) {
        case 204:
          return true;
        default:
          return "Something went wrong while trying to invite the user, please try again later.";
      }
    } catch (error) {
      switch (error.response.status) {
        case 401:
          return "Your session has expired, please login again.";
        case 403:
          return "You have insufficient permissions to view this information.";
        case 404:
          return "New invitation could not be sent for this user.";
        default:
          return "Something went wrong while trying send a new invite to the user, please try again later.";
      }
    }
  }

  async resetPasswordForUser(userId: string): Promise<boolean | string> {
    try {
      const resetPasswordResult = await axios.post(`${BASE_API_URLS.IDS}user/${userId}/reset`);

      switch (resetPasswordResult.status) {
        case 204:
          return true;
        default:
          return "Something went wrong while trying to invite the user, please try again later.";
      }
    } catch (error) {
      switch (error.response.status) {
        case 401:
          return "Your session has expired, please login again.";
        case 403:
          return "You have insufficient permissions to view this information.";
        case 404:
          return "New invitation could not be sent for this user.";
        default:
          return "Something went wrong while trying send a new invite to the user, please try again later.";
      }
    }
  }
}
