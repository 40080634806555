import { makeUserManager } from "react-oidc";
import OIDCConfiguration from "../configuration/OIDCConfiguration";
import { User } from "oidc-client";

export class UserManagerService {
    
    // single instance (prevents issues in oidc-client)
    private static userManager = makeUserManager(OIDCConfiguration);

    getUserManager() {
        return UserManagerService.userManager;
    }

    getUser(): Promise<User | null> {
        return UserManagerService.userManager.getUser();
    }

    signOut(): Promise<void> {
        return UserManagerService.userManager.clearStaleState().then(() => UserManagerService.userManager.signoutRedirect());
    }

    redirectToSignin() {
        return UserManagerService.userManager.signinRedirect();
    }

    refreshToken() {
        return UserManagerService.userManager.signinSilent();
    }
}
