const TABLEHEADERS = {
  COUNTRY: "country",
  DATE: "date",
  SPORT: "sport",
  HANDLEDBY: "handledBy",
  ASSIGNEDTO: "isAssigned",
  EMAIL: "email",
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  ROLE: "role",
  CONFIRMED: "isConfirmed",
  ACTIONS: 'actions',
};

export default TABLEHEADERS;
