import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./assets/style/styles.scss";
import * as serviceWorker from "./serviceWorker";

// Constants
import ROUTES from "./constants/routes";

// OIDC
import { makeAuthenticator, Callback } from "react-oidc";

// Pages
import App from "./App";
import axios from "./api/baseApi";
import { UserManagerService } from "./services/UserManagerService";

const userService = new UserManagerService();
const AppWithAuth = makeAuthenticator({
  userManager: userService.getUserManager(),
  signinArgs: {
    state: {
      foo: 15,
    },
  },
})(App);

// Add interceptor to set access token on the request
axios.interceptors.request.use(
  function (config) {
    return userService.getUser().then((user) => {
      if (user?.expired) {
        return userService.refreshToken().catch(() => { userService.signOut(); return null });
      }
      return Promise.resolve(user);
    }).then((user) => {
      config.headers["Authorization"] = `Bearer ${user?.access_token}`;
      return config;
    });
  },
  function (error) {
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route
          path="/login"
          render={(routeProps) => (
            <Callback
              onSuccess={(user) => {
                // `user.state` will reflect the state that was passed in via signinArgs.
                routeProps.history.push(ROUTES.REPORT);
              }}
              userManager={userService.getUserManager()}
            />
          )}
        />
        <AppWithAuth />
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
