import axios from "../api/baseApi";

import BASE_API_URLS from "../constants/ApiBaseUrls";

interface CodeCreationInterface {
  country: string;
  sport: string;
  codesToGenerate: number;
  codeUses: number;
}

export default class CodeService {
  async createCodes(
    codeCreation: CodeCreationInterface
  ): Promise<string[] | undefined> {
    try {
      const payload = {
        countryCode: codeCreation.country,
        sportName: codeCreation.sport,
        numberOfPinsToCreate: codeCreation.codesToGenerate,
        numberOfUsage: codeCreation.codeUses,
      };

      const result = await axios.post(
        `${BASE_API_URLS.BE}token/pin/create`,
        payload
      );

      if (result.status === 200) {
        return result.data;
      } else {
        return undefined;
      }
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  async getCodesForCountrySportCombination(
    country: string,
    sport: string
  ): Promise<number | undefined> {
    try {
      const result = await axios.get(
        `${BASE_API_URLS.BE}token/pin/count/${country}/${sport}`
      );

      if (result.status === 200) {
        return result.data;
      } else {
        return undefined;
      }
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
}
