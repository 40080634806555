import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserData } from "react-oidc";
import Modal from "react-modal";

// Services
import { isAdmin } from "../services/RoleService";
import CountrySportCombinationService from "../services/CountrySportCombinationService";
import CodeService from "../services/CodeService";

// Interfaces
import Handler from "../Interfaces/Entities/Handler";

// Consts
import ROUTES from "../constants/routes";

// Components
import PageWrapper from "../components/molecules/PageWrapper";
import CountrySportSelector from "../components/molecules/CountrySportSelector";
import NewCodesDialog from "../components/molecules/NewCodesDialog";

const countrySportCombinationService = new CountrySportCombinationService();
const codeService = new CodeService();
const PlayerReportCodes: React.FC<any> = () => {
  const [country, setCountry] = useState<string>("");
  const [sport, setSport] = useState<string>("");
  const [isCreateCodeModalOpen, setIsCreateCodeModalOpen] = useState<boolean>(
    false
  );
  const [codesForUnion, setCodesForUnion] = useState<number>(0);
  const [generatedCodes, setGeneratedCodes] = useState<string[]>([]);
  const [error, setError] = useState<string>("");
  const history = useHistory();
  const { user } = useContext(UserData);

  useEffect(() => {
    if (!isAdmin(user?.profile.role)) {
      history.push(ROUTES.ROOT);
    }

    if (sport && country) {
      countrySportCombinationService
        .loadCountrySportCombinationDetails(country, sport)
        .then((result) => {
          // Reset values
          setGeneratedCodes([]);

          if (result) {
            // Does this union have an active handler?
            if (result.handledByFifpro || result.handlers.length > 0) {
              const hasActiveHandler = result.handlers.find(
                (h: Handler) => h.isActive
              );

              if (result.handledByFifpro || hasActiveHandler) {
                codeService
                  .getCodesForCountrySportCombination(country, sport)
                  .then((result) => {
                    if (result) {
                      setCodesForUnion(result);
                      setError("");
                    } else {
                      setCodesForUnion(0);
                    }
                  });
              } else {
                setCodesForUnion(0);
                setError(
                  "This combination has no active handlers, so codes can not be created."
                );
              }
            }
          } else {
            setError("There is no union for this combination.");
            setCodesForUnion(0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [user, history, sport, country]);

  const generateCodes = async (codesToGenerate: number) => {
    if (codesToGenerate === 0) {
      return;
    }

    codeService
      .createCodes({
        country: country,
        sport: sport,
        codesToGenerate: codesToGenerate,
        // TODO: decide whether we want to enforce usage of 1 regardless of production
        codeUses: 1,
      })
      .then((result) => {
        if (result) {
          setGeneratedCodes(result ?? []);
          setIsCreateCodeModalOpen(false);
        }
      });
  };

  const generatedCodeItems = () => {
    return generatedCodes.map((code: string) => {
      return (
        <li key={code}>
          <b>{code}</b>
        </li>
      );
    });
  };

  return (
    <main>
      <PageWrapper
        title={`Player Report Codes`}
        backButtonRequired={true}
        panelSize="l-content__large"
      >
        <div className="c-report-codes">
          <h2 className="c-report-codes__title">
            create new or see existing report codes
          </h2>
          <div className="c-report-codes__container">
            <h2 className="c-report-codes__category-title">
              choose country/sport combination
            </h2>
            <div className="c-report-codes__country-sport-selector">
              <CountrySportSelector
                className="c-report-codes__country-sport-selector--separator"
                onCountryChosen={(country) => setCountry(country)}
                onlyUnionItems={true}
                onSportChosen={(sport) => setSport(sport)}
              />
              <button
                onClick={() => setIsCreateCodeModalOpen(true)}
                className="c-button c-button--solid c-report-codes__create"
                disabled={error !== ""}
              >
                Create new Codes
                <span className="icon-plus"></span>
              </button>
            </div>
            {country && sport && error && (
              <span className="c-report-codes__error">
                {error}
              </span>
            )}
          </div>
          {!error && generatedCodes.length === 0 && (
            <h2 className="c-report-codes__title c-report-codes__title">
              Existing codes ({codesForUnion})
            </h2>
          )}
          {!error && generatedCodes.length > 0 && (
            <div className="c-report-codes__generated-codes">
              <h2 className="c-report-codes__title c-report-codes__title">
                Generated codes ({generatedCodes.length})
              </h2>
              <ul>{generatedCodeItems()}</ul>
            </div>
          )}
        </div>
      </PageWrapper>
      <Modal
        isOpen={isCreateCodeModalOpen}
        onRequestClose={() => setIsCreateCodeModalOpen(false)}
        shouldCloseOnEsc={true}
        shouldFocusAfterRender={true}
        shouldCloseOnOverlayClick={true}
      >
        <NewCodesDialog
          onCancel={() => setIsCreateCodeModalOpen(false)}
          onSubmit={(r: number) => generateCodes(r)}
        />
      </Modal>
    </main>
  );
};

export default PlayerReportCodes;
