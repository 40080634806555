import React from "react";

interface ConfirmationDialogInterface {
  title: string;
  body?: string;
  confirmationButtonlabel?: string;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogInterface> = ({
  title,
  body,
  confirmationButtonlabel,
  onCancel,
  onConfirm,
}) => {
  return (
    <div className="c-confirmation-modal__instance">
      <h2>{title}</h2>
      <p>{body}</p>
      <div className="c-confirmation-modal__actions">
        <button
          className="c-button c-button--solid"
          onClick={() => onConfirm()}
        >
          {confirmationButtonlabel ? confirmationButtonlabel : "Save"}
        </button>
        <button className="c-button" onClick={() => onCancel()}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ConfirmationDialog;
