import axios from "../api/baseApi";

// Consts
import USER_ROLES from "../constants/UserRoles";
import BASE_API_URLS from "../constants/ApiBaseUrls";

// Interfaces
import Role from "../Interfaces/Entities/Role";

const hasRole = (role: string, roles: string[]) => roles.includes(role);

const isAdmin = (role: string) => {
  return hasRole(role, [USER_ROLES.SYS_ADMIN, USER_ROLES.ADMIN]);
};

const isCaseHandler = (role: string) => {
  return hasRole(role, [USER_ROLES.CASE_HANDLER]);
};

const isFifproLegal = (role: string) => {
  return hasRole(role, [USER_ROLES.FIFPRO_LEGAL]);
};

export default class RoleService {
  async getRoles(): Promise<Role[] | string> {
    try {
      const rolesResult = await axios.get(`${BASE_API_URLS.IDS}role`);

      switch (rolesResult.status) {
        case 200:
          return rolesResult.data;
        default:
          return "Something went wrong while trying to retrieve the users, please try again later.";
      }
    } catch (error) {
      switch (error.response.status) {
        case 401:
          return "Your session has expired, please login again.";
        case 403:
          return "You have insufficient permissions to view this information.";
        case 404:
          return "Users were not found, please try again later.";
        default:
          return "Something went wrong while trying to retrieve the users, please try again later.";
      }
    }
  }
}

export { isAdmin, isCaseHandler, isFifproLegal };
