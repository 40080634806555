import React, { useState, useEffect } from "react";

// Services
import CountryService from "../../services/CountryService";
import SportService from "../../services/SportService";

// Interfaces
import Sport from "../../Interfaces/Entities/Sport";
import Country from "../../Interfaces/Entities/Country";

interface CountrySportSelectorInterface {
  onCountryChosen: (value: string) => void;
  onSportChosen: (value: string) => void;
  onlyUnionItems: boolean;
  className?: string;
}

const countryService = new CountryService();
const sportService = new SportService();
const CountrySportSelector: React.FC<CountrySportSelectorInterface> = ({
  onCountryChosen,
  onSportChosen,
  className,
  onlyUnionItems,
}) => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [sports, setSports] = useState<Sport[]>([]);

  useEffect(() => {
    sportService.loadAvailableSports(onlyUnionItems).then((results) => {
      if (results) {
        setSports(results);
      }
    });

    countryService.loadAvailableCountries(onlyUnionItems).then((results) => {
      if (results) {
        setCountries(results);
      }
    });
  }, [onlyUnionItems]);

  const countryOptions = () => {
    return countries.map((country: Country) => (
      <option value={country.countryCode} key={country.countryCode}>
        {country.countryName}
      </option>
    ));
  };

  const sportOptions = () => {
    return sports.map((sport: Sport) => (
      <option value={sport.name} key={sport.name}>
        {sport.name}
      </option>
    ));
  };

  return (
    <div className={className}>
      <select
        onChange={(e) => onCountryChosen(e.currentTarget.value)}
        name="country"
        className="c-select"
        placeholder="Country"
        defaultValue=""
      >
        <option value="" disabled>
          Country
        </option>
        {countryOptions()}
      </select>
      <select
        onChange={(e) => onSportChosen(e.currentTarget.value)}
        name="sport"
        className="c-select"
        placeholder="Sport"
        defaultValue=""
      >
        <option value="" disabled>
          Sport
        </option>
        {sportOptions()}
      </select>
    </div>
  );
};

export default CountrySportSelector;
