import axios from "../api/baseApi";
import { Report, ReportDetails } from "../Interfaces/Reports/report";
import { PageResult } from "../Interfaces/generic/page-result";
import fileDownload from "js-file-download";

// Constants
import BASE_API_URLS from "../constants/ApiBaseUrls";

export default class ReportService {
  async loadReports(
    page: number = 1,
    pageSize?: number
  ): Promise<PageResult<Report>> {
    try {
      if (!pageSize) {
        pageSize = +process.env.REACT_APP_TABLE_DEFAULT_PAGESIZE!;
      }

      const response = await axios.get(
        `${BASE_API_URLS.BE}report?page=${page}&pageSize=${pageSize}`
      );

      if (response.status === 200) {
        return {
          results: response.data,
          totalCount: response.headers["x-total-count"],
        };
      } else {
        return { totalCount: 0 };
      }
    } catch (error) {
      return { totalCount: 0 };
    }
  }

  async loadReportDetails(reportId: string): Promise<ReportDetails | string> {
    try {
      const response = await axios.get(
        `${BASE_API_URLS.BE}report/detail?reportId=${reportId}`
      );

      switch (response.status) {
        case 200:
          return response.data;
        default:
          return "Something went wrong, please try again later.";
      }
    } catch (error) {
      switch (error.response.status) {
        case 401:
          return "You are not authorized to view this content.";
        case 403:
          return "You are not authorized to view this content.";
        case 400:
          return "Something went wrong while retrieving the content, please try again later.";
        default:
          return "Something went wrong, please try again later.";
      }
    }
  }

  async downloadReportAttachment(
    attachmentId: string,
    fileName: string
  ): Promise<undefined | string> {
    try {
      const result = await axios.get(
        `${BASE_API_URLS.BE}attachment?attachmentId=${attachmentId}`,
        {
          responseType: "blob",
        }
      );

      switch (result.status) {
        case 200:
          fileDownload(new Blob([result.data]), fileName);
          break;
        default:
          return "Something went wrong, please try again later.";
      }
    } catch (error) {
      switch (error.response.statusCode) {
        case 400:
          return "Something went wrong, please try again later.";
        case 401:
          return "You are not authorized to download this attachment.";
        case 403:
          return "Forbidden to download this attachment";
        default:
          return "Something went wrong, please try again later.";
      }
    }
  }
}
