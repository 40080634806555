import React from "react";

// Interfaces
import NotificationDialogInterface from "../../Interfaces/Dialogs/NotificationDialogInterface";

const NotificationDialog: React.FC<NotificationDialogInterface> = ({
  titleText,
  bodyText,
  confirmButtonLabel,
  onConfirm,
}) => {
  return (
    <div className="c-modal">
      <h2>{titleText}</h2>
      <p>{bodyText}</p>

      <div className="c-modal__row--reverse">
        <button
          className="c-button c-button--solid"
          onClick={() => onConfirm()}
        >
          {confirmButtonLabel}
        </button>
      </div>
    </div>
  );
};

export default NotificationDialog;
