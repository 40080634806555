import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { UserData } from "react-oidc";
import { useForm } from "react-hook-form";
import Modal from "react-modal";

// Services
import { isAdmin } from "../services/RoleService";
import CountrySportCombinationService from "../services/CountrySportCombinationService";
import UserService from "../services/UserService";

// Interfaces
import {
  CountrySportCombinationDetails,
  NewCountrySportCombinationShell,
} from "../Interfaces/CountrySportCombinations/CountrySportCombination";
import Handler from "../Interfaces/Entities/Handler";
import DialogInterface from "../Interfaces/Dialogs/DialogInterface";

// Components
import PageWrapper from "../components/molecules/PageWrapper";
import ConfirmationDialog from "../components/molecules/ConfirmationDialog";

// Constants
import ROUTES from "../constants/routes";
import DIALOG_TYPES from "../constants/DialogTypes";

const countrySportCombinationService = new CountrySportCombinationService();
const accountService = new UserService();

const CountrySportCombinationEdit: React.FC = () => {
  const { country, sport, isNew } = useParams();
  const { user } = useContext(UserData);
  const history = useHistory();
  const [handlerDraft, setHandlerDraft] = useState<string>("");
  const [handlerSettingsVisible, setHandlerSettingsVisible] = useState<boolean>(
    true
  );
  const [confirmation, setConfirmation] = useState<DialogInterface>({
    type: "",
    id: "",
    open: false,
  });
  const [details, setDetails] = useState<
    CountrySportCombinationDetails | NewCountrySportCombinationShell
  >();
  const [isMutable, setIsMutable] = useState(false);
  const [error, setError] = useState("");

  const { handleSubmit, errors, register } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (user) {
      if (!isAdmin(user.profile.role)) {
        history.push(ROUTES.REPORT);
      }

      if (isNew) {
        setHandlerSettingsVisible(false);
        countrySportCombinationService
          .loadPrefilledUnion(country, sport)
          .then((result) => {
            if (result) {
              result.addressLine1 = result.addressLineOne;
              setDetails(result ?? {});
              setIsMutable(false);
            } else {
              setDetails(
                {
                  unionCode: "",
                  name: "",
                  shortName: "",
                  addressLine1: "",
                  addressLine2: "",
                  postalCode: "",
                  city: "",
                  province: "",
                  logoPath: "",
                  logoBackgroundColor: "",
                  contactPersonName: "",
                  contactEmailAddress: "",
                  contactPhoneNumber: "",
                  defaultLanguage: "",
                  translationGroup: "",
                  handledByFifpro: false,
                  handler: "",
                } ?? {}
              );
              setIsMutable(true);
            }
          });
      } else {
        countrySportCombinationService
          .loadCountrySportCombinationDetails(country, sport)
          .then((result) => {
            if (result && result) {
              setDetails(result ?? {});
              setIsMutable(result.isMutable);
              setHandlerSettingsVisible(!result.handledByFifpro);
            }
          });
      }
    }
  }, [country, sport, user, history, isNew]);

  const handlerItems = () => {
    return details!.handlers!.map((handler) => {
      return (
        <div key={handler.id} className="c-csc__field">
          <strong>Handler*</strong>
          <input
            type="email"
            className="c-input input-button"
            value={handler.email}
            readOnly={true}
          />
          <button
            type="button"
            className="c-button c-button--no-margin c-button--solid c-csc__handler-button"
            onClick={() =>
              setConfirmation({
                type: DIALOG_TYPES.REMOVE_HANDLER,
                id: handler.id,
                open: true,
              })
            }
          >
            <span className="icon-trash"></span>
          </button>
        </div>
      );
    });
  };

  const setEmptyConfirmation = () =>
    setConfirmation({ type: "", id: "", open: false });

  const removeHandler = () => {
    if (details && details.handlers && details.handlers.length > 0) {
      setDetails({
        ...details,
        handlers: details.handlers.filter((h) => h.id !== confirmation.id),
      });
      setEmptyConfirmation();
    }
  };

  const saveHandler = (handler: Handler) => {
    if (details && details.handlers) {
      details.handlers!.push(handler);
      setDetails({
        ...details,
        handlers: details.handlers,
      });
      setHandlerDraft("");
      setError("");
      setConfirmation({ type: "", id: undefined, open: false });
    }
  };

  const addHandler = () => {
    if (details && handlerDraft.replace(/\s/g, '').length > 0) {
      accountService
        .getUserByEmail(handlerDraft)
        .then((validatedHandlerObject: any | string) => {
          if (validatedHandlerObject && validatedHandlerObject.length > 0) {
            countrySportCombinationService
              .isUserAssignedToOtherUnion(details.unionCode, handlerDraft)
              .then((isAssignedToOtherUnion: boolean | string) => {

                if (typeof isAssignedToOtherUnion === "string") {
                  setError(isAssignedToOtherUnion);
                  return;
                }

                if (
                  isAssignedToOtherUnion &&
                  typeof validatedHandlerObject[0] === "object"
                ) {
                  setConfirmation({
                    type: DIALOG_TYPES.ADD_BUSY_UNION_HANDLER,
                    id: validatedHandlerObject[0].id,
                    open: true,
                  });
                } else if (
                  !isAssignedToOtherUnion &&
                  typeof validatedHandlerObject[0] === "object"
                ) {
                  saveHandler(validatedHandlerObject[0]);
                }
              });
          } else if (typeof validatedHandlerObject === "string") {
            setError(validatedHandlerObject);
          } else {
            setError("User does not exist in the system.");
          }
        });
    }
  };

  const onSubmit = (data: CountrySportCombinationDetails) => {
    try {
      if (!isNew && !data.handledByFifpro && details!.handlers!.length === 0) {
        setError(
          "Either add at least one handler, or mark FIFPRO as the handler."
        );
        return;
      }

      if (!isNew) {
        data.handlers = details!.handlers;
      }

      countrySportCombinationService
        .saveCountrySportCombination(country, sport, data)
        .then((result) => {
          if (result.success) {
            history.push(`/sport-combinations/details/${country}/${sport}`);
          }
          if (result.error) {
            setError(error);
          }
        });
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  };

  const onCancel = () => {
    if (isNew) {
      history.push(ROUTES.SPORT_COMBINATION_OVERVIEW);
    } else {
      history.push(`/sport-combinations/details/${country}/${sport}`);
    }
  };

  const decideWhichConfirmationToShow = () => {
    switch (confirmation.type) {
      case DIALOG_TYPES.REMOVE_HANDLER:
        return (
          <ConfirmationDialog
            title="Remove handler?"
            body="Are you sure you want to remove this handler from this country/sport combination? This will take effect after saving."
            confirmationButtonlabel="Remove"
            onCancel={() => setEmptyConfirmation()}
            onConfirm={() => removeHandler()}
          />
        );
      case DIALOG_TYPES.ADD_BUSY_UNION_HANDLER:
        return (
          <ConfirmationDialog
            title="Add handler to extra union?"
            body="This handler has already been added to another union. Are you sure you want to add this handler to this union as well?"
            confirmationButtonlabel="Add"
            onCancel={() => setEmptyConfirmation()}
            onConfirm={() =>
              saveHandler({
                email: handlerDraft,
                id: confirmation.id!,
                isActive: true,
              })
            }
          />
        );
    }
  };

  if (!details) {
    return (
      <main>
        <PageWrapper
          title={`${country}/${sport}`}
          backButtonRequired={true}
          panelSize="l-content__large"
        >
          <div className="c-loader"></div>
        </PageWrapper>
      </main>
    );
  }

  return (
    <main>
      <PageWrapper
        title={`${country}/${sport}`}
        backButtonRequired={true}
        panelSize="l-content__large"
      >
        {!isNew && <h2 className="c-csc__sub-header">{`Edit ${country}/${sport}`}</h2>}
        <div className="c-csc__container">
          <div
            className="c-csc__logo"
            style={{ background: details?.logoBackgroundColor || "#221c46" }}
          >
            <img src={details?.logoPath} alt={details?.name} />
          </div>
          <div className="c-csc__details-block">
            <h2 className="c-csc__category-title">Union</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="c-csc__field">
                <strong>Code*</strong>
                <input
                  type="text"
                  name="unionCode"
                  ref={register({ required: true })}
                  className={errors.unionCode ? "c-input is-error" : "c-input"}
                  placeholder="Union code"
                  readOnly={!isNew}
                  defaultValue={details?.unionCode}
                />
                {errors.unionCode && (
                  <span className="c-csc__error">Code is required</span>
                )}
              </div>
              <div className="c-csc__field">
                <strong>Name*</strong>
                <input
                  type="text"
                  name="name"
                  ref={register({ required: true })}
                  className={errors.name ? "c-input is-error" : "c-input"}
                  placeholder="Name"
                  defaultValue={details?.name}
                  readOnly={!isMutable}
                />
                {errors.name && (
                  <span className="c-csc__error">Name is required</span>
                )}
              </div>
              <div className="c-csc__field">
                <strong>Short name*</strong>
                <input
                  type="text"
                  name="shortName"
                  ref={register({ required: true })}
                  className={errors.shortName ? "c-input is-error" : "c-input"}
                  placeholder="Short name"
                  defaultValue={details?.shortName}
                  readOnly={!isMutable}
                />
                {errors.shortName && (
                  <span className="c-csc__error">Short name is required</span>
                )}
              </div>
              <div className="c-csc__field">
                <strong>Logo file name*</strong>
                <input
                  type="text"
                  name="logoPath"
                  readOnly={!isMutable}
                  ref={register({ required: isMutable })}
                  className="c-input"
                  placeholder="Name of the logo"
                  defaultValue={details?.logoPath}
                />
                {errors.logoPath && isMutable && (
                  <span className="c-csc__error">Logo is required</span>
                )}
              </div>
              <div className="c-csc__field">
                <strong>Background</strong>
                <label className="c-checkbox">
                    <input
                      type="checkbox"
                      ref={register}
                      className="c-checkbox__input"
                      name="logoBackgroundColor"
                      value="#FFF"
                      defaultChecked={details.logoBackgroundColor !== null}
                    />
                    <span className="c-checkbox__label">White background color</span>
                  </label>
              </div>
              <div className="c-csc__details-block">
                <h2 className="c-csc__category-title">Union address</h2>
                <div className="c-csc__field">
                  <strong>Address line 1*</strong>
                  <input
                    type="text"
                    name="addressLine1"
                    ref={register({ required: true })}
                    className={
                      errors.addressLine1 ? "c-input is-error" : "c-input"
                    }
                    placeholder="Address line 1"
                    defaultValue={details?.addressLine1}
                    readOnly={!isMutable}
                  />
                  {errors.addressLine1 && (
                    <span className="c-csc__error">
                      1st address line is required
                    </span>
                  )}
                </div>
                <div className="c-csc__field">
                  <strong>Address line 2</strong>
                  <input
                    type="text"
                    name="addressLine2"
                    ref={register}
                    className="c-input"
                    placeholder="Address line 2"
                    defaultValue={details?.addressLine2}
                    readOnly={!isMutable}
                  />
                </div>
                <div className="c-csc__field">
                  <strong>Postal code</strong>
                  <input
                    type="text"
                    name="postalCode"
                    ref={register}
                    className="c-input"
                    placeholder="Postal code"
                    defaultValue={details?.postalCode}
                    readOnly={!isMutable}
                  />
                </div>
                <div className="c-csc__field">
                  <strong>City</strong>
                  <input
                    type="text"
                    name="city"
                    ref={register}
                    className="c-input"
                    placeholder="City"
                    defaultValue={details?.city}
                    readOnly={!isMutable}
                  />
                </div>
                <div className="c-csc__field">
                  <strong>Province</strong>
                  <input
                    type="text"
                    name="province"
                    ref={register}
                    className="c-input"
                    placeholder="Province"
                    defaultValue={details?.province}
                    readOnly={!isMutable}
                  />
                </div>
              </div>
              <div className="c-csc__details-block">
                <h2 className="c-csc__category-title">Union Contact Info</h2>
                <div className="c-csc__field">
                  <strong>Contact person name*</strong>
                  <input
                    type="text"
                    name="contactPersonName"
                    ref={register({ required: true })}
                    className={
                      errors.contactPersonName ? "c-input is-error" : "c-input"
                    }
                    placeholder="Contact name"
                    defaultValue={details?.contactPersonName}
                  />
                  {errors.contactPersonName && (
                    <span className="c-csc__error">
                      Contact name is required
                    </span>
                  )}
                </div>
                <div className="c-csc__field">
                  <strong>Contact email address*</strong>
                  <input
                    type="email"
                    name="contactEmailAddress"
                    ref={register({ required: true })}
                    className={
                      errors.contactEmailAddress
                        ? "c-input is-error"
                        : "c-input"
                    }
                    placeholder="Email address"
                    defaultValue={details?.contactEmailAddress}
                  />
                  {errors.contactEmailAddress && (
                    <span className="c-csc__error">
                      Contact email address is required
                    </span>
                  )}
                </div>
                <div className="c-csc__field">
                  <strong>Contact phone number*</strong>
                  <input
                    type="text"
                    name="contactPhoneNumber"
                    ref={register({ required: true })}
                    className={
                      errors.contactPhoneNumber ? "c-input is-error" : "c-input"
                    }
                    placeholder="Phone number"
                    defaultValue={details?.contactPhoneNumber}
                  />
                  {errors.contactPhoneNumber && (
                    <span className="c-csc__error">
                      Contact phone number is required
                    </span>
                  )}
                </div>
              </div>
              <div className="c-csc__details-block">
                <h2 className="c-csc__category-title">Extra options</h2>
                <div className="c-csc__field">
                  <strong>Default language</strong>
                  <select
                    ref={register}
                    name="defaultLanguage"
                    className="c-select"
                    defaultValue={details?.defaultLanguage}
                  >
                    <option value="en">English</option>
                    <option value="es">Spanish</option>
                    <option value="fr">France</option>
                  </select>
                </div>
                <div className="c-csc__field">
                  <strong>Translation group</strong>
                  <input
                    type="text"
                    name="translationGroup"
                    ref={register}
                    className="c-input"
                    placeholder="Translation group"
                    defaultValue={details?.translationGroup}
                  />
                </div>
                {!isNew && (
                  <div className="c-csc__details-block">
                    <h2 className="c-csc__category-title">Handler</h2>
                    <div className="c-csc__field">
                      <strong>FIFPRO as handler</strong>
                      <label className="c-checkbox">
                        <input
                          type="checkbox"
                          ref={register}
                          className="c-checkbox__input"
                          name="handledByFifpro"
                          defaultChecked={details?.handledByFifpro}
                          onChange={(event) =>
                            setHandlerSettingsVisible(!event.target.checked)
                          }
                        />
                        <span className="c-checkbox__label">Use FIFPRO as handler</span>
                      </label>
                    </div>
                    {handlerSettingsVisible && handlerItems()}
                    {handlerSettingsVisible && (
                      <div className="c-csc__field">
                        <strong>Handler</strong>
                        <input
                          type="text"
                          name="handler"
                          ref={register}
                          className="c-input input-button"
                          placeholder="Handler email address"
                          onChange={(e) => setHandlerDraft(e.target.value)}
                          value={handlerDraft}
                        />
                        <button
                          type="button"
                          className="c-button c-button--no-margin c-button--solid c-csc__handler-button"
                          onClick={() => addHandler()}
                        >
                          <span className="icon-plus"></span>
                        </button>
                        {error && <span className="c-csc__error">{error}</span>}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="c-csc__actions">
                <button
                  onClick={() => onCancel()}
                  className="c-button"
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="c-button c-button--solid"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </PageWrapper>
      <Modal
        isOpen={confirmation.open}
        onRequestClose={() => setEmptyConfirmation()}
        shouldCloseOnEsc={true}
        shouldFocusAfterRender={true}
        shouldCloseOnOverlayClick={true}
      >
        {decideWhichConfirmationToShow()}
      </Modal>
    </main>
  );
};

export default CountrySportCombinationEdit;
