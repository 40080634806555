import React, { useContext, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { UserData } from "react-oidc";

// Assets
import logo from "../../assets/images/FIFPRO_logo__lg.svg";

// Consts
import ROUTES from "../../constants/routes";

// Services
import { isAdmin } from "../../services/RoleService";
import { UserManagerService } from "../../services/UserManagerService";

interface headerInterface {
  title: string;
  backButtonRequired?: boolean;
  panelSize?: string;
}

const userService = new UserManagerService();

const PageWrapper: React.FC<headerInterface> = ({
  title,
  panelSize,
  children,
  backButtonRequired,
}) => {
  const [canSeeReports, setCanSeeReports] = useState(false);
  const [canManageConsole, setCanManageConsole] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const history = useHistory();
  const { user } = useContext(UserData);

  useEffect(() => {
    let userRole;
    if (user) {
      userRole = user.profile.role;

      if (isAdmin(userRole)) {
        setCanManageConsole(true);
        setCanSeeReports(false);
      }

      if (!isAdmin(userRole)) {
        setCanManageConsole(false);
        setCanSeeReports(true);
      }
    }
  }, [user]);

  const signoutHandler = () => {
    userService.signOut().catch((error) => {
      console.log(error);
    });
  };

  const menu = () => {
    return (
      <ul className="c-subnavigation">
        {canSeeReports && (
          <li className="c-subnavigation__list-item">
            <Link to={ROUTES.REPORT}>
              <button className="c-subnavigation__link c-subnavigation__link--current">
                Report
              </button>
            </Link>
          </li>
        )}
        {canManageConsole && (
          <li className="c-subnavigation__list-item">
            <Link to={ROUTES.SPORT_COMBINATION_OVERVIEW}>
              <button className="c-subnavigation__link">
                Sport/Country overview
              </button>
            </Link>
          </li>
        )}
        {canManageConsole && (
          <li className="c-subnavigation__list-item">
            <Link to={ROUTES.PLAYER_REPORT_CODES}>
              <button className="c-subnavigation__link">Report Codes</button>
            </Link>
          </li>
        )}
        {canManageConsole && (
          <li className="c-subnavigation__list-item">
            <Link to={ROUTES.USER_OVERVIEW}>
              <button className="c-subnavigation__link">Users</button>
            </Link>
          </li>
        )}
        <li className="c-subnavigation__list-item">
          <button className="c-subnavigation__link" onClick={signoutHandler}>
            Logout
          </button>
        </li>
      </ul>
    );
  };

  return (
    <div className="l-main">
      <div className="l-header-bg">
        <div className="c-header"></div>
      </div>
      <div className="l-header-top">
        <div className="l-header-top__left">
          <img className="c-logo" src={logo} alt="logo" />
        </div>
        <div className="l-header-top__right">
          <nav className="c-navigation">
            <ul className="c-navigation__list">
              <li className="c-navigation__list-item">
                <span className="icon-login"></span>
              </li>
              <li className="c-navigation__list-item is-hidden-small">
                <span className="c-navigation__text">{user?.profile.name}</span>
              </li>
              <li className="c-navigation__list-item c-navigation__hamburger">
                <button
                  className={
                    "c-navigation__link " +
                    (isMenuActive ? "c-navigation__link--active" : "")
                  }
                  onClick={() => setIsMenuActive(!isMenuActive)}
                >
                  <span className="icon-menu-nav"></span>
                </button>
                {isMenuActive && menu()}
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div className="l-header-bottom">
        <div className="l-header-bottom__left">
          {backButtonRequired && (
            <button
              className="c-button c-button--back"
              type="button"
              onClick={() => history.push(ROUTES.ROOT)}
            >
              <span className="icon-arrow-back"></span>
            </button>
          )}
        </div>
        <div className="l-header-bottom__right">
          <h1>{title}</h1>
        </div>
      </div>
      <div className="l-content">
        <div className={panelSize}>
          <div className="c-panel">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default PageWrapper;
