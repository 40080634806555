import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router";
import { ColumnWithLooseAccessor, usePagination, useTable } from "react-table";
import moment from "moment";
import { UserData } from "react-oidc";

// Services
import ReportService from "../services/ReportService";
import { isAdmin, isFifproLegal, isCaseHandler } from "../services/RoleService";

// Components
import PageWrapper from "../components/molecules/PageWrapper";
import PaginationButtons from "../components/molecules/PaginationButtons";
import DataTable from "../components/molecules/DataTable";

// Constants
import TABLEHEADERS from "../constants/TableHeaders";
import ROUTES from "../constants/routes";

// Interfaces
import { Report } from "../Interfaces/Reports/report";

const reportService = new ReportService();

const ReportOverview: React.FC = () => {
  const { user } = useContext(UserData);
  const [exportAvailable] = useState(false);
  const [rows, setRows] = useState<Report[]>([]);
  const [columns, setColumns] = useState<ColumnWithLooseAccessor[]>([]);
  const [pages, setPages] = useState(0);

  const history = useHistory();

  const {
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: rows,
      pageCount: pages,
      initialState: {
        pageIndex: 0,
        pageSize: +process.env.REACT_APP_TABLE_DEFAULT_PAGESIZE!,
      },
      manualPagination: true,
    },
    usePagination
  );

  useEffect(() => {
    let userRole;

    if (user) {
      userRole = user.profile.role;

      if (isAdmin(userRole)) {
        history.push(ROUTES.SPORT_COMBINATION_OVERVIEW);
      }

      if (isCaseHandler(userRole)) {
        setColumns([
          {
            Header: "Country",
            accessor: TABLEHEADERS.COUNTRY,
            Cell: ({ value }) => {
              return value;
            },
          },
          {
            Header: "Date",
            accessor: TABLEHEADERS.DATE,
            Cell: ({ value }) => {
              return moment(value).format(
                process.env.REACT_APP_DATETIME_FORMAT
              );
            },
          },
          {
            Header: "Sport",
            accessor: TABLEHEADERS.SPORT,
            Cell: ({ value }) => {
              return value;
            },
          },
        ]);
        fetchAndSetReports();
      }

      if (isFifproLegal(userRole)) {
        setColumns([
          {
            Header: "Country",
            accessor: TABLEHEADERS.COUNTRY,
            Cell: ({ value }) => {
              return value;
            },
          },
          {
            Header: "Date",
            accessor: TABLEHEADERS.DATE,
            Cell: ({ value }) => {
              return moment(value).format(
                process.env.REACT_APP_DATETIME_FORMAT
              );
            },
          },
          {
            Header: "Sport",
            accessor: TABLEHEADERS.SPORT,
            Cell: ({ value }) => {
              return value;
            },
          },
          {
            Header: "Handled by",
            accessor: TABLEHEADERS.HANDLEDBY,
            Cell: ({ value }) => {
              switch (value) {
                case 0:
                  return "FIFPRO";
                case 1:
                  return "Union";
                default:
                  return "Unknown";
              }
            },
          },
          {
            Header: "Is assigned",
            accessor: TABLEHEADERS.ASSIGNEDTO,
            Cell: ({ value }) => {
              if (value) {
                return (
                  <mark>
                    Assigned<span className="icon-check"></span>
                  </mark>
                );
              } else {
                return "Unassigned";
              }
            },
          },
        ]);
        fetchAndSetReports();
      }
    }

    function fetchAndSetReports() {
      reportService.loadReports(pageIndex + 1, pageSize).then((res) => {
        const mappedRows = res.results?.map((row: any) => {
          return {
            ...row,
            country: row.country.countryName,
            sport: row.sport.name,
          };
        });
        setRows(mappedRows ?? []);

        setPages(Math.ceil(res.totalCount / pageSize));
      });
    }
  }, [pageIndex, pageSize, history, user]);

  const rowClicked = (row: any) => {
    history.push(`/report/${row.original.reportId}`);
  };

  return (
    <main className="">
      <PageWrapper title="Overview Reports" panelSize="l-content__large">
        <div className="c-table-filters">
          <div className="c-table-filters__filter">
            {/*TODO: re-enable this in the right userstory*/}
            {/*<select className={'c-select'} defaultValue="NLD">
              <option value="" selected>Country</option>
              <option value="NLD">The Netherlands</option>
              <option value="ESP">Spain</option>
            </select>*/}
          </div>
          {/*TODO: re-enable this in the right userstory*/}
          {/*          <div className="c-table-filters__filter c-table-filters__filter--reset">
            <button className={'c-link'}>Reset</button>
          </div>*/}
        </div>
        <div className="l-scroll-container l-scroll-container--table">
          <DataTable columns={columns} data={rows} onRowClick={rowClicked} />
        </div>

        <div className="c-table-footer">
          <div className="c-pagination">
            {PaginationButtons(pages, pageIndex, gotoPage)}
          </div>
          {exportAvailable && (
            <button className="c-button c-button--solid">export to pdf</button>
          )}
        </div>
      </PageWrapper>
    </main>
  );
};

export default ReportOverview;
