import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// Components
import CountrySportSelector from "./CountrySportSelector";

// Services
import CountrySportCombinationService from "../../services/CountrySportCombinationService";

// Interfaces
import NewCountrySportCombinationDialogInterface from "../../Interfaces/CountrySportCombinations/NewCountrySportCombinationDialogInterface";

const countrySportCombinationService = new CountrySportCombinationService();
const NewCountrySportCombinationDialog: React.FC<NewCountrySportCombinationDialogInterface> = ({
  dismissModal,
}) => {
  const history = useHistory();
  const [country, setCountry] = useState<string>(""); // TODO: get better default value
  const [sport, setSport] = useState<string>(""); // TODO: get better default value
  const [combinationError, setCombinationError] = useState<string>("");
  const [incompleteError, setIncompleteError] = useState<string>("");

  // TODO: refactor these props into an interface
  const onSubmit = async () => {
    if (!country || !sport) {
      setIncompleteError("Country and sport must be selected.");
      return;
    }

    setIncompleteError("");

    const doesCombinationExist = await countrySportCombinationService.checkIfCombinationExists(
      { country, sport }
    );

    if (doesCombinationExist) {
      setCombinationError("This combination already exists.");
    } else {
      history.push(`/sport-combinations/edit/${country}/${sport}/isNew`);
    }
  };

  return (
    <div className="c-modal__instance c-new-csc">
      <h2>New Country/Sport</h2>
      <p>
      Choose which Country and Sport combination you want to add to the Red Button App. After sending the information this Country and Sport combination will be added to the App and the Console.
      </p>
      <CountrySportSelector
        onCountryChosen={(value) => setCountry(value)}
        onSportChosen={(value) => setSport(value)}
        onlyUnionItems={false}
        className="c-new-csc__input-container"
      />
      {combinationError && (
        <p className="c-new-csc__error">
          {combinationError}
        </p>
      )}
      {incompleteError && (
          <p className="c-new-csc__error">
            {incompleteError}
          </p>
      )}
      <div className="c-new-csc__actions">
        <input
          className="c-button c-button--solid"
          type="submit"
          onClick={() => onSubmit()}
        />
        <button className="c-button" onClick={() => dismissModal()}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default NewCountrySportCombinationDialog;
