import React from "react";

// Data
import Icons from "./icons.json"

// Interfaces
import IconComponentInterface from "./interfaces/IconComponentInterface";
import IconType from "./interfaces/IconType";

const Icon: React.FC<IconComponentInterface> = ({
  viewBox,
  icon,
  className,
  color,
}) => {
  const iconToRender: IconType | undefined = Icons.available.find((i) => i.name === icon);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox}
      width="24"
      height="24"
      fill={iconToRender!.filled ? color : "transparent"}
      stroke={!iconToRender!.filled ? color : "transparent"}
    >
      <path
        d={iconToRender!.path}
        transform={iconToRender!.transformation}
      ></path>
    </svg>
  );
};

export default Icon;
