import { UserManagerSettings } from "oidc-client";

const OIDCConfiguration: UserManagerSettings = {
  //Required
  authority: `${process.env.REACT_APP_OIDC_AUTHORITY}`,
  client_id: `${process.env.REACT_APP_OIDC_CLIENT_ID}`,
  redirect_uri: `${process.env.REACT_APP_OIDC_CLIENT_BASE_URL}login`,
  response_type: `${process.env.REACT_APP_OIDC_RESPONSE_TYPE}`,
  scope: `${process.env.REACT_APP_OIDC_SCOPE}`,
  stopCheckSessionOnError: true,
  //Optional
  post_logout_redirect_uri: `${process.env.REACT_APP_OIDC_CLIENT_BASE_URL}logout`,
  loadUserInfo: true,
};

export default OIDCConfiguration;