import React from "react";

// Components
import Icon from "../atoms/icons/Icon";

// Constants
import PAGINATION_TYPES from "../../constants/PaginationTypes";

// Interfaces
import PaginationItemInterface from "../../Interfaces/Tables/PaginationItemInterface";

const PaginationButtons = (
  pages: number,
  pageIndex: number,
  gotoPage: (updater: ((pageIndex: number) => number) | number) => void
) => {
  let buttons: any = [];
  let allButtons: PaginationItemInterface[] = [];
  let buttonsToRender: PaginationItemInterface[] = [];

  for (let i = 0; i < pages; i++) {
    allButtons.push({
      type: PAGINATION_TYPES.PAGE,
      value: i,
      displayValue: i + 1,
    });
  }

  if (pageIndex === 0) {
    // 1st page
    buttonsToRender = allButtons.slice(0, 3);

    // Are there more buttons than rendered? Add skip buttons
    if (pages > 3) {
      buttonsToRender.push(
        {
          type: PAGINATION_TYPES.BETWEEN,
          value: 3,
        },
        {
          type: PAGINATION_TYPES.PAGE,
          value: pages - 1,
          displayValue: pages,
        },
        {
          type: PAGINATION_TYPES.ARROW_FORWARD,
          value: pageIndex + 1,
        }
      );
    }
  } else if (pageIndex === pages - 1) {
    // Last page
    let extraButtonsToRender = allButtons.reverse().slice(0, 3).reverse();
    let functionalButtons: PaginationItemInterface[] = [
      {
        type: PAGINATION_TYPES.ARROW_BACK,
        value: pageIndex - 1,
      },
      {
        type: PAGINATION_TYPES.PAGE,
        value: 0,
        displayValue: 1,
      },
      {
        type: PAGINATION_TYPES.BETWEEN,
        value: -300,
      },
    ];

    if (pages > 3) {
      buttonsToRender = buttonsToRender.concat(
        functionalButtons,
        extraButtonsToRender
      );
    } else {
      buttonsToRender = extraButtonsToRender;
    }
  } else if (pageIndex > 0 && pageIndex < pages - 1) {
    // Between page
    let extraButtonsToRender = allButtons.slice(pageIndex - 1, pageIndex + 2);
    let preFunctionalButtons: PaginationItemInterface[] = [];
    let postFunctionalButtons: PaginationItemInterface[] = [];

    if (pages > 3 && pageIndex === 2) {
      extraButtonsToRender = allButtons.slice(0, 1).concat(extraButtonsToRender);
    }

    if (pages > 3 && pageIndex === pages - 3) {
      extraButtonsToRender = extraButtonsToRender.concat(allButtons.slice(pages - 1, pages));
    }

    if (pageIndex - 2 > 0) {
      preFunctionalButtons = [
        {
          type: PAGINATION_TYPES.ARROW_BACK,
          value: pageIndex - 1,
        },
        {
          type: PAGINATION_TYPES.PAGE,
          value: 0,
          displayValue: 1,
        },
        {
          type: PAGINATION_TYPES.BETWEEN,
          value: -310,
        },
      ];
    }

    if (pageIndex + 3 < pages) {
      postFunctionalButtons = [
        {
          type: PAGINATION_TYPES.BETWEEN,
          value: -320,
        },
        {
          type: PAGINATION_TYPES.PAGE,
          value: pages - 1,
          displayValue: pages,
        },
        {
          type: PAGINATION_TYPES.ARROW_FORWARD,
          value: pageIndex + 1,
        },
      ];
    }

    buttonsToRender = buttonsToRender.concat(
      preFunctionalButtons,
      extraButtonsToRender,
      postFunctionalButtons
    );
  }

  buttonsToRender.forEach((paginationItem: PaginationItemInterface) => {
    switch (paginationItem.type) {
      case PAGINATION_TYPES.ARROW_FORWARD:
        buttons.push(
          <button
            key={`pbutton-arrow-${paginationItem.value}`}
            onClick={() => gotoPage(pageIndex + 1)}
          >
            <Icon icon="fifpro-next-arrow" color="white" viewBox="0 0 24 24" />
          </button>
        );
        break;
      case PAGINATION_TYPES.ARROW_BACK:
        buttons.push(
          <button
            key={`pbutton-arrow-${paginationItem.value}`}
            onClick={() => gotoPage(pageIndex - 1)}
          >
            <Icon icon="fifpro-back-arrow" color="white" viewBox="0 0 24 24" />
          </button>
        );
        break;
      case PAGINATION_TYPES.BETWEEN:
        buttons.push(
          <button
            key={`pbutton-between-${paginationItem.value}`}
            className="is-more"
            disabled={true}
          >
            <span className="icon-more"></span>
          </button>
        );
        break;
      default:
        buttons.push(
          <button
            key={`pbutton-${paginationItem.value}`}
            className={paginationItem.value === pageIndex ? "is-active" : ""}
            onClick={() => gotoPage(paginationItem.value)}
          >
            {paginationItem.value + 1}
          </button>
        );
        break;
    }
  });

  return buttons;
};

export default PaginationButtons;
