import React, { useState } from "react";

interface NewCodesDialogInterface {
  onCancel: () => void;
  onSubmit: (codesToGenerate: number) => void;
}

const NewCodesDialog: React.FC<NewCodesDialogInterface> = ({
  onCancel,
  onSubmit,
}) => {
  const [codesToGenerate, setCodesToGenerate] = useState<number>(0);
  const [codeUses, setCodeUses] = useState<number>(1);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const submitAction = () => {
      setIsSubmitting(true);

      onSubmit(codesToGenerate);
  }

  return (
    <div className="c-modal">
      <h2>New player report codes</h2>

      <p>
      Please select the number of codes you want to create in the left column. You can use the arrows or simply type in a number. When you press the submit button the codes will be generated and displayed on the screen.
      </p>

      <div className="c-modal__row">
        <input
          type="number"
          name="code-number"
          className="c-input"
          placeholder="Codes to generate"
          defaultValue={codesToGenerate}
          onChange={(e) => setCodesToGenerate(parseInt(e.target.value) | 0)}
        />
        <input
          type="number"
          name="code-number"
          className="c-input"
          placeholder="Codes usage"
          defaultValue={codeUses}
          disabled={true}
          onChange={(e) => setCodeUses(parseInt(e.target.value) | 0)}
        />
      </div>

      <div className="c-modal__row--reverse">
        <button
          onClick={submitAction}
          className="c-button c-button--solid"
          disabled={codesToGenerate === 0 || isSubmitting}
        >
          Submit
        </button>
        <button onClick={() => onCancel()} className="c-button c-button">
          Cancel
        </button>
      </div>
    </div>
  );
};

export default NewCodesDialog;
