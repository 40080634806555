const DIALOG_TYPES = {
  REMOVE_HANDLER: "remove-handler",
  ADD_BUSY_UNION_HANDLER: "add-busy-union-handler",
  ADD_NEW_USER: "add-new-user",
  INVITATION_SENT: "invitation-sent",
  RESEND_INVITATION: "resend-invitation",
  RESET_PASSWORD: "reset-password",
  PASSWORD_RESET: "password-reset",
  ERROR: 'error',
};

export default DIALOG_TYPES;
