import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router";
import { UserData } from "react-oidc";
import { ColumnWithLooseAccessor, usePagination, useTable } from "react-table";
import Modal from "react-modal";

// Services
import { isAdmin } from "../services/RoleService";
import UserService from "../services/UserService";

// Components
import PageWrapper from "../components/molecules/PageWrapper";
import PaginationButtons from "../components/molecules/PaginationButtons";
import AddNewUserDialog from "../components/molecules/AddNewUserDialog";
import NotificationDialog from "../components/molecules/NotificationDialog";
import DataTable from "../components/molecules/DataTable";

// Interfaces
import User from "../Interfaces/Entities/User";
import DialogInterface from "../Interfaces/Dialogs/DialogInterface";

// Constants
import ROUTES from "../constants/routes";
import TABLEHEADERS from "../constants/TableHeaders";
import DIALOG_TYPES from "../constants/DialogTypes";
import USER_ACTIONS from "../constants/UserActions";
import ConfirmationDialog from "../components/molecules/ConfirmationDialog";

const userService = new UserService();
const UserOverview: React.FC = () => {
  const { user } = useContext(UserData);
  const history = useHistory();
  const [users, setUsers] = useState<User[]>([]);
  const [columns] = useState<ColumnWithLooseAccessor[]>([
    {
      Header: "Email address",
      accessor: TABLEHEADERS.EMAIL,
      Cell: ({ value }) => {
        return <b>{value}</b>;
      },
    },
    {
      Header: "First name",
      accessor: TABLEHEADERS.FIRST_NAME,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Last name",
      accessor: TABLEHEADERS.LAST_NAME,
      Cell: ({ value }) => {
        return value ? value : "-";
      },
    },
    {
      Header: "Role",
      accessor: TABLEHEADERS.ROLE,
      Cell: ({ value }) => {
        return value.name;
      },
    },
    {
      Header: "Confirmed",
      accessor: TABLEHEADERS.CONFIRMED,
      Cell: ({ value }) => {
        return (
          <span
            className={
              value
                ? "icon-check c-users__icon-confirmed"
                : "c-users__icon-not-confirmed icon-multiply"
            }
          ></span>
        );
      },
    },
    {
      Header: "",
      accessor: TABLEHEADERS.ACTIONS,
      Cell: (row: any) => {
        const usedRow = row.row;
        return (
          <div className="c-users__actions-container">
            <button
              onClick={() => toggleUserActionsList(usedRow.original.id)}
              type="button"
              className="c-button c-users__actions-button--more"
            >
              <span className="icon-more"></span>
            </button>
            {row.value.actionListOpen && (
              <div className="c-users__actions-overlay"></div>
            )}
            <ul
              className={
                row.value.actionListOpen
                  ? "c-users__actions-list c-users__actions-list--visible"
                  : "c-users__actions-list c-users__actions-list--invisible"
              }
            >
              {showActions(row.value.availableActions, { id: usedRow.original.id, email: usedRow.original.email })}
            </ul>
          </div>
        );
      },
    },
  ]);
  const [pages, setPages] = useState(0);
  const [dialog, setDialog] = useState<DialogInterface>({
    type: "",
    id: "",
    open: false,
  });
  const [error, setError] = useState<string>("");

  const {
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: users,
      pageCount: pages,
      initialState: {
        pageIndex: 0,
        pageSize: +process.env.REACT_APP_TABLE_DEFAULT_PAGESIZE!,
      },
      manualPagination: true,
    },
    usePagination
  );

  useEffect(() => {
    let userRole;

    if (user) {
      userRole = user.profile.role;

      if (!isAdmin(userRole)) {
        history.push(ROUTES.ROOT);
      }

      userService
        .getUsers({ page: pageIndex + 1, pageSize: pageSize })
        .then((result: any | string) => {
          if (
            typeof result === "object" &&
            result &&
            result.data &&
            result.data.length > 0
          ) {
            setUsers(
              result.data.map((user: User) => {
                if (user.isConfirmed) {
                  user.actions = {
                    actionListOpen: false,
                    availableActions: [USER_ACTIONS.RESET_PASSWORD],
                  };
                } else {
                  user.actions = {
                    actionListOpen: false,
                    availableActions: [USER_ACTIONS.RESEND_INVITATION],
                  };
                }
                return { ...user };
              })
            );
            setPages(Math.ceil(result.totalCount / pageSize));
          } else if (typeof result === "string") {
            setError(result);
          }
        });
    }
  }, [history, user, pageSize, pageIndex]);

  const toggleUserActionsList = (email: string) => {
    const usersList: User[] = [...users];
    const userToToggle = usersList.find((r: User) => r.email === email);
    if (userToToggle && userToToggle.actions) {
      userToToggle.actions.actionListOpen = !userToToggle.actions
        .actionListOpen;
    }

    setUsers(usersList);
  };

  const showActions = (actions: string[], userIdentity: object) => {
    // eslint-disable-next-line array-callback-return
    return actions.map((action: string) => {
      switch (action) {
        case USER_ACTIONS.RESEND_INVITATION:
          return (
            <li className="c-users__actions-list-item" key={action}>
              <button
                onClick={() =>
                  setDialog({
                    type: DIALOG_TYPES.RESEND_INVITATION,
                    open: true,
                    id: userIdentity,
                  })
                }
                className="c-button c-users__actions-button"
              >
                <span className="icon-mail c-users__icon"></span> {action}
              </button>
            </li>
          );
        case USER_ACTIONS.RESET_PASSWORD:
          return (
            <li className="c-users__actions-list-item" key={action}>
              <button
                onClick={() =>
                  setDialog({
                    type: DIALOG_TYPES.RESET_PASSWORD,
                    open: true,
                    id: userIdentity,
                  })
                }
                className="c-button c-users__actions-button"
              >
                <span className="icon-lock c-users__icon"></span> {action}
              </button>
            </li>
          );
      }
    });
  };

  const rowClicked = (row: any) => {
    toggleUserActionsList(row.values.email);
  };

  const setEmptyDialog = () => setDialog({ type: "", open: false, id: "" });

  const setConfirmationDialogForUserCreation = (result: string) => {
    if (result) {
      setDialog({
        type: DIALOG_TYPES.INVITATION_SENT,
        open: true,
        id: result,
      });
    }
  };

  const reloadAndResetConfirmationDialog = () => {
    window.location.reload();
  };

  const resendInvitation = () => {
    userService.reInviteUser(dialog.id.id).then(sentResult => {
      if (typeof sentResult === "boolean" && sentResult) {
        setDialog({
          open: true,
          type: DIALOG_TYPES.INVITATION_SENT,
          id: dialog.id.email,
        });
      } else if (typeof sentResult === "string") {
        setDialog({
          open: true,
          type: DIALOG_TYPES.ERROR,
          id: sentResult,
        })
      }
    });
  };

  const resetPassword = () => {
    userService.resetPasswordForUser(dialog.id.id).then((passwordResetResult) => {
      if (passwordResetResult) {
        setDialog({
          type: DIALOG_TYPES.PASSWORD_RESET,
          open: true,
          id: dialog.id.email,
        });
      } else if (typeof passwordResetResult === "string") {
        setDialog({
          open: true,
          type: DIALOG_TYPES.ERROR,
          id: passwordResetResult,
        })
      }
    });
  };

  let decideWhichDialogToShow = () => {
    if (dialog.open) {
      switch (dialog.type) {
        case DIALOG_TYPES.ADD_NEW_USER:
          return (
            <AddNewUserDialog
              onSubmit={(result: string) =>
                setConfirmationDialogForUserCreation(result)
              }
              onCancel={() => setEmptyDialog()}
            />
          );
        case DIALOG_TYPES.INVITATION_SENT:
          return (
            <NotificationDialog
              titleText="A new invite has been sent"
              bodyText={`${dialog.id} has received a new invitation to create an account for the Red Button Console.`}
              confirmButtonLabel="Ok"
              onConfirm={() => reloadAndResetConfirmationDialog()}
            />
          );
        case DIALOG_TYPES.RESEND_INVITATION:
          return (
            <ConfirmationDialog
              title="Send a new invitation?"
              body={`${dialog.id!.email} will receive a new invitation to create an account for the Red Button Console.`}
              confirmationButtonlabel="Send"
              onConfirm={() => resendInvitation()}
              onCancel={() => setEmptyDialog()}
            />
          );
        case DIALOG_TYPES.RESET_PASSWORD:
          return (
            <ConfirmationDialog
              title="Reset this users' password?"
              body={`${dialog.id!.email} will receive a mail with instructions on how to reset a password.`}
              confirmationButtonlabel="Send"
              onConfirm={() => resetPassword()}
              onCancel={() => setEmptyDialog()}
            />
          );
        case DIALOG_TYPES.PASSWORD_RESET:
          return (
            <NotificationDialog
              titleText="Password has been reset"
              bodyText={`${dialog.id} has received a mail with instructions on how to reset a password.`}
              confirmButtonLabel="Close"
              onConfirm={() => setEmptyDialog()}
            />
          );
        case DIALOG_TYPES.ERROR:
          return (
              <NotificationDialog
                  titleText="Something went wrong"
                  bodyText={dialog.id}
                  confirmButtonLabel="Close"
                  onConfirm={() => setEmptyDialog()}
              />
          )
      }
    }
  };

  return (
    <main>
      <PageWrapper title="List of users" panelSize="l-content__large">
        <div className="">
          <div className="l-container">
            <div className="c-panel">
              <div className="l-scroll-container l-scroll-container--table">
                <DataTable
                  data={users}
                  columns={columns}
                  onRowClick={rowClicked}
                  actionsEnabled={true}
                />
              </div>
              <div className="c-table-footer c-users__footer">
                {error && <span className="c-users__error">{error}</span>}
                <div className="c-pagination">
                  {PaginationButtons(pages, pageIndex, gotoPage)}
                </div>
                <button
                  className="c-button c-button--solid"
                  onClick={() =>
                    setDialog({
                      type: DIALOG_TYPES.ADD_NEW_USER,
                      open: true,
                      id: undefined,
                    })
                  }
                >
                  Add new user
                  <span className="icon-plus"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
      <Modal
        isOpen={dialog.open}
        onRequestClose={() => setDialog({ ...dialog, open: false })}
        shouldCloseOnEsc={true}
        shouldFocusAfterRender={true}
        shouldCloseOnOverlayClick={true}
      >
        {decideWhichDialogToShow()}
      </Modal>
    </main>
  );
};

export default UserOverview;
