import axios from "../api/baseApi";

// Constants
import BASE_API_URLS from "../constants/ApiBaseUrls";

// Interfaces
import Country from "../Interfaces/Entities/Country";

export default class CountryService {
  async loadAvailableCountries(onlyUnionCountries: boolean): Promise<Country[] | undefined> {
    try {
      const result = await axios.get(`${BASE_API_URLS.BE}country?countryHasUnion=${onlyUnionCountries}`);

      if (result.status === 200) {
        return result.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
}
