import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// Constants
import ROUTES from "./constants/routes";

// Pages
import ReportOverview from "./pages/ReportOverview";
import ReportDetails from "./pages/ReportDetails";
import CountrySportCombinationsOverview from "./pages/CountrySportCombinationsOverview";
import CountrySportCombinationDetails from "./pages/CountrySportCombinationDetails";
import CountrySportCombinationEdit from "./pages/CountrySportCombinationEdit";
import PlayerReportCodes from "./pages/PlayerReportCodes";
import UserOverview from "./pages/UserOverview";

// CSS
import "./assets/style/styles.scss";

const App: React.FC = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path={ROUTES.REPORT_DETAILS} exact>
            <ReportDetails />
          </Route>
          <Route path={ROUTES.REPORT} exact>
            <ReportOverview />
          </Route>
          <Route path={ROUTES.ROOT} exact>
            <ReportOverview />
          </Route>
          <Route path={ROUTES.SPORT_COMBINATION_OVERVIEW} exact>
            <CountrySportCombinationsOverview />
          </Route>
          <Route path={ROUTES.SPORT_COMBINATION_DETAILS} exact>
            <CountrySportCombinationDetails />
          </Route>
          <Route path={ROUTES.SPORT_COMBINATION_EDIT} exact>
            <CountrySportCombinationEdit />
          </Route>
          <Route path={ROUTES.PLAYER_REPORT_CODES} exact>
            <PlayerReportCodes />
          </Route>
          <Route path={ROUTES.USER_OVERVIEW} exact>
            <UserOverview />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
