import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import ReportService from "../services/ReportService";
import moment from "moment";
import { UserData } from "react-oidc";

// Interfaces
import { ReportDetails } from "../Interfaces/Reports/report";

// Services
import { isAdmin, isFifproLegal, isCaseHandler } from "../services/RoleService";

// Constants
import ROUTES from "../constants/routes";

// Components
import PageWrapper from "../components/molecules/PageWrapper";
const reportService = new ReportService();

const ReportDetailsPage: React.FC = () => {
  const [
    canReadHandlerAndAssignedTo,
    setCanReadHandlerAndAssignedTo,
  ] = useState(false);
  const [reportDetails, setReportDetails] = useState<ReportDetails>();
  const [error, setError] = useState<string>("");
  const history = useHistory();
  const { user } = useContext(UserData);
  const { id } = useParams();

  useEffect(() => {
    let userRole;
    if (user) {
      userRole = user.profile.role;

      if (isAdmin(userRole)) {
        history.push(ROUTES.SPORT_COMBINATION_OVERVIEW);
      }

      if (isCaseHandler(userRole)) {
        setCanReadHandlerAndAssignedTo(false);
      }

      if (isFifproLegal(userRole)) {
        setCanReadHandlerAndAssignedTo(true);
      }

      reportService.loadReportDetails(id).then((res) => {
        if (typeof res === "object") {
          setReportDetails(res);
        } else {
          setError(res);
        }
      });
    }
  }, [id, user, history]);

  const downloadAttachment = (id: string, fileName: string) => {
    if (!id) {
      return;
    }

    reportService.downloadReportAttachment(id, fileName);
  };

  let attachmentItems = () => {
    if (reportDetails?.attachments) {
      return reportDetails.attachments.map((attachment: any) => {
        return (
          <li key={attachment.id} className="c-attachment">
            <span>{attachment.fileName}</span>
            <button
              type="button"
              className="c-button c-button--no-margin c-attachment__link"
              onClick={() => downloadAttachment(attachment.id, attachment.fileName)}
            >
              <span className="icon-download"></span>
            </button>
          </li>
        );
      });
    }
  };

  if (!reportDetails && !error) {
    return (
      <main>
        <PageWrapper
          title="Report details"
          backButtonRequired={true}
          panelSize="l-content__small"
        >
          <div className="c-loader"></div>
        </PageWrapper>
      </main>
    );
  }

  const waysOfContactTotal = () => {
    let total = 0;
    if (reportDetails) {
      if (reportDetails.wayOfContact.iWasContacted) {
        total += 1;
      }
      if (reportDetails.wayOfContact.colleagueWasContacted) {
        total += 1;
      }
      if (reportDetails.wayOfContact.heardARumor) {
        total += 1;
      }
      if (reportDetails.wayOfContact.other) {
        total += 1;
      }
    }

    return total;
  };
  const waysOfContact = () => {
    if (reportDetails) {
      return (
        <ul className={"c-report__list"}>
          {reportDetails.wayOfContact.iWasContacted && (
            <li className={"c-report__list-item"}>I was contacted</li>
          )}
          {reportDetails.wayOfContact.colleagueWasContacted && (
            <li className={"c-report__list-item"}>A colleague was contacted</li>
          )}
          {reportDetails.wayOfContact.heardARumor && (
            <li className={"c-report__list-item"}>Heard a rumor</li>
          )}
          {reportDetails.wayOfContact.other && (
            <li className={"c-report__list-item"}>Something else</li>
          )}
        </ul>
      );
    }
  };
  const handledBy = (handledBy: number) => {
    switch (handledBy) {
      case 0:
        return "FIFPRO";
      case 1:
        return "Union";
      default:
        return "Unknown";
    }
  };

  return (
    <main>
      <PageWrapper
        title="Report details"
        backButtonRequired={true}
        panelSize="l-content__small"
      >
        <div className="c-report">
          {reportDetails && !error && (
            <div className="c-report__content">
              <div className="c-report__row">
                <b className="c-report__row-top">Date and time of Report</b>
                <span className="c-report__row-top">
                  {moment(reportDetails.reportedAt).format(
                    process.env.REACT_APP_DATETIME_FORMAT
                  )}
                </span>
              </div>
              <div className="c-report__row">
                <b>Country</b>
                <span>{reportDetails.country.countryName}</span>
              </div>
              <div className="c-report__row">
                <b>Sport</b>
                <span>{reportDetails.sport.name}</span>
              </div>
              <div className="c-report__row">
                <b className="c-report__row-top">Way of contact</b>
                <span className="c-report__row-top is-subtle">
                  Chosen reason(s): {waysOfContactTotal()}
                </span>
                <div className="c-report__row-bottom">{waysOfContact()}</div>
              </div>
              <div className="c-report__row">
                <b className="c-report__row-top">Information about the fix</b>
                <span className="c-report__row-bottom">
                  {reportDetails.information}
                </span>
              </div>
              <div className="c-report__row">
                <b className="c-report__row-top">Country of Competition</b>
                <span className="c-report__row-bottom">
                  {reportDetails.countryOfCompetition.countryName}
                </span>
              </div>
              <div className="c-report__row">
                <b className="c-report__row-top">Contact details</b>
                <span className="c-report__row-bottom">
                  {reportDetails.contactInformation.name || "Not available"}
                </span>
              </div>
              <div className="c-report__row">
                <b className="c-report__row-top">Details</b>
                <span className="c-report__row-bottom">
                  {reportDetails.contactInformation.contactDetails ||
                    "Not available"}
                </span>
              </div>
              <div className={"c-report__row"}>
                <b className={"c-report__row-top"}>Attachments</b>
                <span className={"c-report__row-top is-subtle"}>
                  Added attachment(s): {reportDetails.attachments.length || 0}
                </span>
                <div className={"c-report__row-bottom"}>
                  {reportDetails.attachments.length === 0 && (
                    <span>No attachments</span>
                  )}
                  <ul className={"c-attachments"}>{attachmentItems()}</ul>
                </div>
              </div>
              {canReadHandlerAndAssignedTo && (
                <div className="c-report__row">
                  <b className="c-report__row-top">Assigned to</b>
                  <span className="c-report__row-top">-</span>
                </div>
              )}
              {canReadHandlerAndAssignedTo && (
                <div className="c-report__row">
                  <b className="c-report__row-top">Handled by</b>
                  <span className="c-report__row-top">
                    {handledBy(reportDetails.handledBy)}
                  </span>
                </div>
              )}
            </div>
          )}
          {!reportDetails && error && (
            <div className="c-report__content">
              <span className="c-csc__error">{error}</span>
            </div>
          )}
          <div className="c-report__footer">
            <div className="l-button-container">
              {/*              {canReadHandlerAndAssignedTo && <button className={"c-button c-button--solid"} disabled={true}>
                Assign To
              </button>}*/}
            </div>
          </div>
        </div>
      </PageWrapper>
    </main>
  );
};

export default ReportDetailsPage;
