import axios from "../api/baseApi";

// Constants
import BASE_API_URLS from "../constants/ApiBaseUrls";

// Interfaces
import Sport from "../Interfaces/Entities/Sport";

export default class SportService {
  async loadAvailableSports(onlyUnionSports?: boolean): Promise<Sport[] | undefined> {
    try {
      const result = await axios.get(`${BASE_API_URLS.BE}sport?sportHasUnion=${onlyUnionSports}`);

      if (result.status === 200) {
        return result.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
}
