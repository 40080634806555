import axios, { AxiosResponse, AxiosError } from "axios";
import { UserManagerService } from "../services/UserManagerService";

const userManagerService = new UserManagerService();

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json";

// Add interceptor to signout when a call returns a 401 (unauthorised)
axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    if (
      error &&
      error.response &&
      (error.response.status === 401)
    ) {
      userManagerService.redirectToSignin();
    }

    return Promise.reject(error);
  }
);

export default axios;
