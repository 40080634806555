import React from "react";
import { usePagination, useTable } from "react-table";

// Interfaces
import TableInterface from "../../Interfaces/Tables/TableInterface";

const DataTable: React.FC<TableInterface> = ({
  columns,
  data,
  onRowClick,
  actionsEnabled,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: +process.env.REACT_APP_TABLE_DEFAULT_PAGESIZE!,
      },
      manualPagination: true,
    },
    usePagination
  );

  return (
    <table className="c-table" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")} </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} onClick={() => onRowClick(row)}>
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className={`is-${cell.column.id}`}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
              {!actionsEnabled && (
                <td className="is-cta">
                  <span className="icon-arrow-next"></span>
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default DataTable;
